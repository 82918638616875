import React from 'react'
import ShipmentPage from '../components/shipmentPage/ShipmentPage'

function Shipment() {
  return (
    <div>
      <ShipmentPage />
    </div>
  )
}

export default Shipment