import React from 'react'
import { Link } from 'react-router-dom'
import './Offers.css'
import exclusive_image from '../Assets/exclusive_image.png'
const Offers = () => {
  return (
    <div className='offers'>
        <div className="offers-left">
            <h1>Exclusive</h1>
            <h1>Offers For Women</h1>
            <p>ONLY ON BEST SELLERS PRODUCTS</p>
            <Link to='/womens' style={{textDecoration: 'none', color: 'inherit'}}> <button>Check Now</button> </Link>
        </div>
        <div className="offers-right">
            <img src={exclusive_image} alt="" />
        </div>

    </div>
  )
}

export default Offers