import React from 'react'
import CartItems from '../components/cartItems/CartItems'

function Cart() {
  return (
    <div>
      <CartItems />
    </div>
  )
}

export default Cart