import React from 'react'
import PaymentPage from '../components/paymentPage/PaymentPage'

function Payment() {
  return (
    <div>
      <PaymentPage />
    </div>
  )
}

export default Payment